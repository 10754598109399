import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'

import './index.css'
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

const Main = () => {
    return (
        <React.StrictMode>
            <MantineProvider defaultColorScheme="dark" forceColorScheme='dark'>
                <Notifications withBorder color="yellow" position="top-center" />
                <App />
            </MantineProvider>
        </React.StrictMode>
    );
};

if('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/service-worker.js')
        .then(function() { console.log('Service Worker Registered'); });
}

ReactDOM.createRoot(document.getElementById('root')).render(<Main />)