const NavBar = () => {
  return (
    <div className='w-full flex flex-col gap-4 items-center justify-center py-10 border-b-[1px] border-[#353535]'>
        <img src="./logo.webp" className="w-[170px]" />
        <div className="hidden sm:flex items-center gap-8 text-white text-xl font-corporate small-caps">
            <a href="#offer" className="tracking-wider">Ponuda</a>
            <a href="#services" className="tracking-wider">Usluge</a>
            <a href="#gallery" className="tracking-wider">Galerija</a>
            <a href="#about" className="tracking-wider">O nama</a>
            <a href="#partners" className="tracking-wider">Partneri</a>
            <a href="#footer" className="tracking-wider">Kontakt</a>
        </div>
    </div>
  )
}

export default NavBar