const About = () => {
  return (
    <div className="w-full flex flex-col items-center gap-14 py-14 border-b-[1px] border-[#353535] px-5" id="about">
        <h1 className="text-white text-5xl font-corporate small-caps font-semibold tracking-[15px] mr-[-15px]">o nama</h1>
        <p className="text-center max-w-7xl text-lg text-white opacity-[0.6] font-light">
        Sve je pocelo 2021.
        <br />
Mi smo centar za nadogradnju i sredjivanje enterijera vaseg ljubimca.
<br />
Osnovan od strane ljubitelja automobila za ljubitelje automobila. BeltsShop™️ pruza usluge kao sto su:
<br />
<br />
* zamena sigurnosnih auto pojaseva(traka) na vas postojeci mehanizam(skinute mehanizme koje vi donesete)
<br />
* ugradnju i zamenu sigurnosnih pojaseva po zakazanon terminu u boji po zelji
<br />
<br />
Kod nas mozete osveziti izgled vaseg enterijera i ulepsati ga zamenom pojaseva u boji. Nasi autopojasevi su proizvodjaca Autoliv. Ne guzvaju se, ne tricave se i tesko se guzvaju. Imate dozivotnu garanciju. Atestirani su na trzaj do 2,5t / 25000 N
        </p>
    </div>
  )
}

export default About